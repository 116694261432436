import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Modal from "../Modal";
import "../../styles/markdown.css";

const MaterialViewerModal = ({ modalOpen, setModalOpen, selected, markdownContent }) => {
  return (
    <Modal isOpen={modalOpen.material} onClose={() => setModalOpen((prev) => ({ ...prev, ["material"]: false }))} width="6xl">
      <div className="p-6 max-w-6xl mx-auto bg-white rounded-lg shadow-lg overflow-y-auto max-h-[80vh] space-y-4">
        {/* <h2 className="text-3xl font-semibold text-gray-800 text-center">{selected.material?.fileName ?? selected.material.file}</h2> */}
        <div className="prose max-w-none mx-auto text-start markdown-body">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{selected.material.content}</ReactMarkdown>
        </div>
        <button onClick={() => setModalOpen((prev) => ({ ...prev, ["material"]: false }))} className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition">
          닫기
        </button>
      </div>
    </Modal>
  );
};

export default MaterialViewerModal;
